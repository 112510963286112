@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
    @apply px-8 py-3 bg-green-500 inline-block text-center text-white leading-10 rounded-xl hover:bg-green-600;
}

@layer utilities{
}

@layer base{
    
}
